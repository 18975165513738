<template>
  <v-container>
    <div class="plan-select-app">
      <transition name="fade">
        <div v-if="currentActivePlan != null" >
          <v-row>
            <v-col cols="12" class="align-center">
              <div class="text-center">
                <svg width="130px" height="30px" viewBox="0 0 130 30">
                  <circle cx="10" cy="10" r="9" class="filled"/>
                  <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                  <circle cx="40" cy="10" r="9" class="filled"/>
                  <line x1="49" y1="10" x2="61" y2="10" class="line"/>
                  <circle cx="70" cy="10" r="9" class="filled"/>
                  <line x1="79" y1="10" x2="91" y2="10" class="line"/>
                  <circle cx="100" cy="10" r="9" class="filled"/>
                </svg>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="top">
                <div class="notice" v-if="currentActivePlan.syncio_plan.active">
                  <div>
                    <h1>Manage Your Plan</h1>
                    <p>
                      Upgrade or downgrade anytime. <u class="intercom-custom-launcher">Contact us</u> for help.
                    </p>
                  </div>
                </div>
                <div class="notice" v-else-if="currentActivePlan.syncio_plan.plan_id != 88">
                  <div>
                    <h1>You might have noticed we have new plans</h1>
                    <p>
                      You have been a Syncio customer for a while, and you are currently on a legacy plan. Don't worry, we haven't
                      made any changes to your account.<br>
                      Consider changing to one of our new plans. <u class="intercom-custom-launcher">Contact us</u> for help.
                    </p>
                  </div>
                </div>
                <v-row class="heading-text-wrapper" v-else>
                  <v-col offset="2" cols="1" class="mt-1">
                    <v-icon x-large @click="$router.go(-1)">
                      keyboard_arrow_left
                    </v-icon>
                  </v-col>
                  <v-col class="primary--text" offset="1" cols="8">
                    <p class="text-h2 ml-5">Select a Plan</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <p class="text-body-1">You will not be charged for the first 14 days. Upgrade anytime. Contact us for help.</p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="step-1-message">
                <p class="text-h4 primary--text">Step 1: Select how many products you need to sync</p>
                <p class="text-body-1">All base plans include real-time & ongoing inventory syncing. Learn more about our pricing. <a href="https://www.syncio.co/pricing" target="_blank">here</a></p>
              </div>
            </v-col>
          </v-row>
          <v-row class="plans-list">
            <plan-desc-block
                v-for="(plan, index) in availablePlansList"
                v-bind:key="index"
                :plan="plan"
            ></plan-desc-block>
          </v-row>
          <v-row class="addon-options">
            <v-col cols="8">
              <div class="step-2-message">
                <p class="text-h4 primary--text"><strong>Step 2:</strong> You may select one or more additional add-ons</p>
                <p class="text-body-1">Optional. Upgrade or cancel anytime.</p>
              </div>
              <v-row class="addons-list" v-if="addons != null">
                <addon-option
                    v-for="(addon, index) in addons"
                    v-bind:key="index"
                    :addon="addon"
                    :index="index"
                ></addon-option>
              </v-row>
            </v-col>
            <v-col cols="4" class="summary-cart">
              <plan-cart-summary></plan-cart-summary>
            </v-col>
          </v-row>
        </div>
      </transition>
      <div v-if="currentActivePlan === null">
        <h2>Just a second. We are preparing your plan right now.</h2>
        <div class="loading">
          <bounce-loader color="#6ACAF1" :loading="true" :size="40"></bounce-loader>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import PlanCartSummary from '@/views/plan/components/PlanCartSummary';
import PlanDescBlock from '@/views/plan/components/PlanDescBlock';
import AddonOption from '@/views/plan/components/AddonOption';
import { BounceLoader } from '@saeris/vue-spinners';

export default {
  name: "PlanSelectionApp",
  data: function() {
    return {
      addons: [
        {
          module_id: 9,
          name: "Custom Sync Module",
          price_per_month: 15,
          description: "Allow customization settings for product synchronization"
        },
        {
          module_id: 1,
          name: "Order Module",
          price_per_month: 19,
          description: "Push / create order in source store after your destination store received an order."
        }
      ],
    };
  },
  computed: {
    ...mapState('plan',['availablePlansList', 'currentActivePlan', 'selectedNewPlan', 'storeType']),
  },
  mounted: function() {
    this.$store.dispatch(`plan/init`);
  },
  components: {
    PlanCartSummary,
    PlanDescBlock,
    AddonOption,
    BounceLoader,
  },
}
</script>

<style lang="scss">
.plan-select-app {
  a {
    text-decoration: underline;
  }
  h2 {
    text-align: center;
  }
  .top {
    margin-bottom: 20px;
    .notice {
      text-align: center;
      background-color: #e3f3ff;
      padding: 20px;
      u {
        color: #007ace;
        cursor: pointer;
      }
    }
  }
  .step-1-message {
    margin-bottom: 20px;
    h4 {
      margin-bottom: 5px;
    }
  }
  .step-2-message {
    margin-bottom: 33px;
    h4 {
      margin-bottom: 5px;
    }
  }
  .plans-list {
    margin-bottom: 15px;
    overflow: hidden;
  }
  .loading {
    text-align: center;
    width: 50px;
    margin: auto;
    padding: 40px;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.filled{
  fill: #fcb058;
  stroke-width: 1;
  stroke: #f57c00;
}
.not-filled{
  fill: none;
  stroke-width: 1;
  stroke: #f57c00;
}
.line{
  stroke-width: 2;
  stroke:#f57c00
}
.heading-text-wrapper{
  margin-top: 2%;
}
</style>