<template>
<v-col cols="6">
  <v-card
      class="addon-wrapper pa-7"
      :class="{'selected': isSelected }"
  >
        <div class="text-h6 text-uppercase color-grey">
          {{ addon.name }}
          <div class="toggle-btn">
            <ToggleButton
                @change="toggleAddon()"
                :labels="{ 'checked': 'ON', 'unchecked': 'OFF' }"
                :width="70"
                :height="30"
                :value="isSelected"
            />
          </div>
        </div>
        <p class="text-body-1 mt-10">{{ addon.description }}</p>
        <p class="text-h5 mt-10 color-navy-hover">+ $<span>{{ addon.price_per_month | numFormat('0.00') }}</span> / mo</p>
  </v-card>
</v-col>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import { mapState } from "vuex";
import $ from 'jquery';
export default {
    props: ['addon', 'index'],
  components:{
    ToggleButton
  },
    computed: {
        ...mapState('plan',[ 'selectedNewPlan' ]),
        isSelected: function() {
            let moduleId = this.addon.module_id;
            let hasSelected = false;
            if (this.selectedNewPlan != null && typeof(this.selectedNewPlan.addon) != 'undefined') {
                $.each(this.selectedNewPlan.addon, (key, item) => {
                    if (item != null && item.module_id === moduleId && item.selected) {
                        hasSelected = true;
                    }
                });
            }

            return hasSelected;
        },
    },
    methods: {
        toggleAddon: function() {
            if (this.selectedNewPlan != null) {
                let moduleId = this.addon.module_id;
                let hasAddon = false;
                $.each(this.selectedNewPlan.addon, (index, item) => {
                    if (item.module_id === moduleId && typeof(item.selected) != 'undefined') {
                        this.selectedNewPlan.addon[index].selected = !item.selected;
                        hasAddon = true;
                        return;
                    }
                });
                if (!hasAddon) {
                    let newAddon = Object.assign({}, this.addon);
                    newAddon.selected = true;
                    this.selectedNewPlan.addon.push(newAddon);
                }
                this.$store.commit('plan/SET_SELECTED_NEW_PLAN', this.selectedNewPlan);
            }
        }
    }
}
</script>

<style lang="scss">
.color-grey{
  color: #495057;
}
.color-navy-hover{
  color: #145b78;
  font-weight: 600 !important;
}
.toggle-btn {
  float: right;
  margin-top: -5px;
}
.addon-wrapper {
  height: 229px;
  width: 391px;
    // &.selected {
    //     border: #2F61EC 2px solid;
    //     box-sizing: border-box;
    // }
    .available-addon {
        h4 {
            width: 100%;
            display: inline-block;

        }
        .btn-wrapper {
            > h5 {
                color: #2F61EC;
                font-weight: 700;
            }
            .button {
                float: right;
            }
        }
    }
}
</style>