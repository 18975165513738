<template>
    <v-col cols="2" class="plan-desc-block" v-if="plan != null" v-on:click="setPlan(plan.id)">
        <div :class="{ 'current' :  isCurrentPlan }">
            <div class="header">CURRENT PLAN</div>
            <v-card
                class="text-left pa-4"
                :class="{ 'selected': isSelected }"
            >
                <input
                    class="radio-input"
                    type="radio"
                    :checked="selectedNewPlan != null && selectedNewPlan.id === plan.id"
                />
                <small class="text-h6 text-uppercase name">
                    <strong>{{ plan.name }}</strong>
                </small>
                <div class="description ">
                    <span><span class="text-h4">{{ plan.limit }}</span> <p class="text-body-1 text-lowercase">Products</p></span>
                </div>
                <div>
                    <h5 class="cost text-h5" v-if="plan.price_per_month != 0.0">
                        <strong>{{ plan.price_per_month | numFormat("$0") }} / mo</strong><br>
                    </h5>
                </div>
            </v-card>
        </div>
    </v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "PlanDescBlock",
    props: ['plan'],
    data: function() {
        return {

        };
    },
    computed: {
        ...mapState('plan',[ 'selectedNewPlan', 'currentActivePlan']),
        isCurrentPlan: function() {
            if (this.currentActivePlan != null) {
                return this.currentActivePlan.syncio_plan.plan_id === this.plan.id;
            } else {
                return false;
            }
        },
        isSelected: function() {
            if (this.selectedNewPlan != null) {
                return this.selectedNewPlan.id === this.plan.id;
            } else {
                return false;
            }
        }
    },
    methods: {
        setPlan: function(planId) {
            this.$store.commit('plan/SET_SELECTED_NEW_PLAN_BY_ID', planId);
        },
    }
}
</script>

<style lang="scss">
.cost {
  color: #145b78 !important;
  }
    .plan-desc-block {
        position: relative;
        &.column {
            &.two {
                max-width: 155px;
            }
        }
        .selected {
            border: #2F61EC 2px solid;
            box-sizing: border-box;
          color: #5c6ac4;
        }
        .name {
            color: #495057;
        }
        .radio-input {
          position: absolute;
          height: 30px;
          width: 30px;
          color: #5c6ac4;
          right: 10px;
        }
        .card {
            h3 {
                margin-top:10px;
            }
            .description {
                .features {
                    text-align: left;
                    font-size: 10px;
                    margin-bottom: 15px;
                    > div {
                        i {
                            color: #2f61ec;
                            margin-right: 5px;
                        }
                    }
                }
            }
            small {
                font-size: 12px;
                font-weight: 500;
            }
        }
        .header {
            visibility: hidden;
            color: #fff;
            background-color: #62cd6a;
            text-align: center;
            padding: 3px;
        }
        .current {
            .header {
                visibility: visible;
            }
            > .card {
                border: #62cd6a 2px solid;
                border-radius: 0px;
            }
        }
    }
</style>