<template>
    <v-card class="plan-cart-summary pa-5">
        <div class="text-h5 selection-text">Your Selection:</div>
        <div v-if="currentActivePlan != null">
            <div class="base plan">
                <div class="text-h6 mt-5 mb-2">BASE PLAN</div>
                <table class="mt-5 color-navy-hover" v-if="currentActivePlan.syncio_plan.plan_id != 88">
                    <tr :class='{"cross-text": (selectedNewPlan != null && selectedNewPlan.id != currentActivePlan.syncio_plan.plan_id)}'>
                        <td class="text-body-1 color-navy-hover">{{ currentActivePlan.name }}</td>
                        <td class="text-h5 plan-summary-price">
                            {{ currentActivePlan.price | numFormat("$0.00") }}
                        </td>
                    </tr>
                </table>
                <table>
                    <tr class="mt-5 color-navy-hover" v-if="(selectedNewPlan != null && selectedNewPlan.id != null && selectedNewPlan.id != currentActivePlan.syncio_plan.plan_id)">
                        <td class="plan-name text-body-1">
                            <i class="fas fa-times plan-reset-x" v-on:click="resetPlan()"></i>
                            {{ selectedNewPlan.name }}
                        </td>
                        <td class="text-h5 plan-summary-price">
                            {{ selectedNewPlan.price_per_month | numFormat("$0.00") }}
                        </td>
                    </tr>
                    <tr class="text-body-1 mt-5 color-navy-hover" v-else-if="(currentActivePlan.syncio_plan.plan_id === 88)">
                        <td class="plan-name text-body-1">
                            Please select a new plan
                        </td>
                        <td>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="extra plan">
                <div class="text-h6 mt-2 mb-2">ADD-ONS</div>
                <table class="current" v-if="currentActivePlan.addons != null && currentActivePlan.addons.length">
                    <tr class="text-body-1 mt-5 color-navy-hover"
                        :class='{"cross-text": shouldCrossOutOldAddonLineItem(feature)}'
                        v-for="(feature, index) in currentActivePlan.addons" v-bind:key="index"
                    >
                        <td class="plan-name text-body-1">
                            {{ feature.name }}
                        </td>
                        <td class="text-h5 plan-summary-price">
                            {{ feature.price_per_month | numFormat("$0.00") }}
                        </td>
                    </tr>
                </table>
                <table class="empty" v-else-if="selectedNewPlan.addon === null || selectedNewPlan.addon.length < 1 || allSelectedAddonOff">
                    <tr class="mt-5 color-navy-hover">
                        <td class="plan-name text-body-1">
                            Please select any addon you want
                        </td>
                        <td>
                        </td>
                    </tr>
                </table>
                <table class="new" v-if="selectedNewPlan != null">
                    <tr class="text-body-1 mt-5 color-navy-hover" v-for="(feature, index) in selectedNewPlan.addon" v-bind:key="index"
                        :class='{"hide": shouldHideNewAddonLineItem(feature)}'
                    >
                        <td v-if="feature != null && feature.selected" class="plan-name text-body-1">
                            <!-- <i class="fas fa-times" v-on:click="removeAddon(index)"></i> -->
                            {{ feature.name }}
                        </td>
                        <td class="text-h5 plan-summary-price" v-if="feature != null && feature.selected">
                            {{ feature.price_per_month | numFormat("$0.00") }}
                        </td>
                    </tr>
                </table>
            </div>
            <hr>
            <div class="total-price mt-2">
                <table>
                    <tr class="current">
                        <td class="text-body-1 plan-name current_total">
                            <span>CURRENT TOTAL:</span>
                        </td>
                        <td class="text-h5 plan-summary-price color-navy-hover">
                            <span>{{ currentTotalPrice | numFormat("$0.00") }} </span>
                            <span>p/m</span>
                            <!-- <small v-if="selectedNewPlan.cap > 0">* Capped at {{ selectedNewPlan.price_per_month + selectedNewPlan.cap + totalAddonFees | numFormat('$0.00') }} /m inclusive usage</small> -->
                            <!-- <small v-else>No cap available</!-->
                        </td>
                    </tr>
                    <tr>
                        <td class="text-body-1 plan-name current_total">
                            <span>NEW TOTAL:</span>
                        </td>
                        <td class="text-h5 plan-summary-price color-navy-hover">
                            <span>{{ totalPrice | numFormat("$0.00") }} </span>
                            <span>p/m</span>
                            <!-- <small v-if="selectedNewPlan.cap > 0">* Capped at {{ selectedNewPlan.price_per_month + selectedNewPlan.cap + totalAddonFees | numFormat('$0.00') }} /m inclusive usage</small> -->
                            <!-- <small v-else>No cap available</!-->
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="submit-btn" v-if="selectedNewPlan === null || (selectedNewPlan != null && typeof(selectedNewPlan.id) === 'undefined' ) || selectedNewPlan.id === 88">
          <v-btn
              block
              class="primary disable-events"
              height="48px"
              @click="goToDashboard"
          >Continue      <v-icon class="right-arrow">
            mdi-arrow-right
          </v-icon></v-btn
          >
        </div>
        <div class="submit-btn" v-else>
            <form id="planUpdateForm" method="post" action="/retailers/v2/upgrade">
                <input type="hidden" :value="csrfToken" name="_token"/>
                <input type="hidden" name="to" :value="selectedNewPlan.id"/>
                <div v-for="(addon, index) in selectedNewPlan.addon" v-bind:key="index">
                    <input type="hidden" name="addon[]" :value="JSON.stringify(addon)"  
                        v-if="addon != null && addon.selected"
                    />
                </div>
              <v-btn
                  block
                  class="primary"
                  height="48px"
                  @click="goToDashboard"
              >Continue      <v-icon class="right-arrow">
                mdi-arrow-right
              </v-icon></v-btn
              >
            </form>
        </div>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
    name: "PlanCartSummary",
    data: function() {
        return { 
            csrfToken: null,
        };
    },
    computed: {
        ...mapState('plan',['selectedNewPlan', 'currentActivePlan']),
        allSelectedAddonOff: function() {
            let allOff = true;
            this.selectedNewPlan.addon.forEach((feature) => {
                if (feature.selected) {
                    allOff = false;
                }
            });

            return allOff;
        },
        totalPrice: function() {
            if (this.selectedNewPlan != null) {
                return parseFloat(this.selectedNewPlan.price_per_month) + this.totalAddonFees;
            }
            return 0.0;
        },
        currentTotalPrice() {
            if (this.currentActivePlan != null) {
                return parseFloat(this.currentActivePlan.price) + this.currentTotalAddonFees;
            }
            return 0;
        },
        currentTotalAddonFees: function () {
            let addonFees = 0.0;
            if (this.currentActivePlan != null && typeof(this.currentActivePlan.addons) != 'undefined' && this.currentActivePlan.addons.length) {
                this.currentActivePlan.addons.forEach((feature) => {
                    if (feature != null && feature.selected) {
                        addonFees += parseFloat(feature.price_per_month);
                    }
                });
                return addonFees;
            }
            return parseFloat(0);
        },
        totalAddonFees: function() {
            let addonFees = 0.0;
            if (this.selectedNewPlan != null && typeof(this.selectedNewPlan.addon) != 'undefined' && this.selectedNewPlan.addon.length) {
                this.selectedNewPlan.addon.forEach((feature) => {
                    if (feature != null && feature.selected) {
                        addonFees += parseFloat(feature.price_per_month);
                    }
                });
                return addonFees;
            }
            return parseFloat(0);
        },
    },
    methods: {
        removeAddon: function(index) {
            this.selectedNewPlan.addon[index].selected = false;
            this.$store.commit('plan/SET_SELECTED_NEW_PLAN', this.selectedNewPlan);
        },
        resetPlan: function() {
            let addons = Object.assign([], this.selectedNewPlan.addon);
            this.$store.commit('plan/SET_SELECTED_NEW_PLAN_BY_ID', this.currentActivePlan.syncio_plan.plan_id);
            this.selectedNewPlan.addon = addons;
            this.$store.commit('plan/SET_SELECTED_NEW_PLAN', this.selectedNewPlan);
        },
        shouldHideNewAddonLineItem: function(newPlanFeature) {
            let matchingCurrentPlanFeature = null;
            $.each(this.currentActivePlan.addons, (index, item) => {
                if (item.module_id === newPlanFeature.module_id) {
                    matchingCurrentPlanFeature = item;
                    return;
                }
            });
            
            return (
                this.selectedNewPlan != null && 
                this.currentActivePlan != null && 
                matchingCurrentPlanFeature != null && 
                matchingCurrentPlanFeature.selected === newPlanFeature.selected &&
                matchingCurrentPlanFeature.price_per_month === newPlanFeature.price_per_month
            );
        },
        shouldCrossOutOldAddonLineItem: function(currentPlanFeature) {
            let matchingNewPlanFeature = null;
            $.each(this.selectedNewPlan.addon, (index, item) => {
                if (item.module_id === currentPlanFeature.module_id) {
                    matchingNewPlanFeature = item;
                    return;
                }
            });

            return (
                matchingNewPlanFeature === null ||
                (
                    this.selectedNewPlan != null && 
                    typeof(this.selectedNewPlan.addon) != "undefined" &&
                    !matchingNewPlanFeature.selected
                ) ||
                currentPlanFeature.price_per_month != matchingNewPlanFeature.price_per_month
            );
        },
      goToDashboard(){
          this.$router.push({name: "DashboardPage"})
      }
    }
}
</script>

<style lang="scss">
.color-grey{
  color: #495057;
}
.color-navy-hover{
  color: #145b78;
}
.plan-name{
  width: 161px;
  height: 31px;
}
.plan-summary-price{
  font-weight: 500 !important;
}
.current_total{
  font-weight: 600 !important;
}
    .plan-cart-summary {
        overflow: hidden;
        width: 320px;
        .selection-text{
          font-weight: 600;
        }
        .base {
            &.plan {
                table {
                    &.selected-plan {
                    }
                    .cross-text {
                        td {
                            padding-bottom: 0px !important;
                        }
                    }
                }
            }
        }
        h4 {
            .fa-eraser {
                float: right;
            }
        }
        .submit-btn {
            float: right;
            width: 100%;
        }
        .cross-text {
            text-decoration: line-through;
            color: #7c7c7c;
        }
        .summary-group {
            font-weight: 700;
            color: #6c6c6c;
        }
        table {
            tr {
                vertical-align: top;
                td {
                    &:nth-child(2) {
                        text-align: right;
                    }
                    .plan-reset-x {
                        margin-right: 15px;
                        color: #999;
                    }
                }
            }
        }
        .total-price {

        }
        .extra {
            .fa-times {
                color: #eee;
                margin-right: 5px;
                &:hover {
                    color: #fa757b;
                }
            }
            .hide {
                display: none;
            }
            table {
                &.empty,
                &.current {
                    margin-bottom: 0px;
                }
                &.new {
                    margin-bottom: 32px;
                }
                tr {
                    td {
                        padding-bottom: 0px !important;
                    }
                }
            }
        }
        .button {
            width: 100%;
            &.checkout {
                background: none;
                background-color: #88bc51;
                box-shadow: inset 0 1px 0 0 #88bc51, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
                border: .1rem solid #7fb14b;
            }
        }
    }
.disable-events {
  opacity: 0.5;
  pointer-events: none
}
</style>